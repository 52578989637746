function handle_global_error(ev) {
    if(window._explicit_die) {
        window._explicit_die = false;
        return;
    }
    const msg = ev.message || ev.reason || JSON.stringify(ev);
    alert(msg);
}

window.addEventListener('error', handle_global_error);
window.addEventListener('unhandledrejection', handle_global_error);
window.onerror = (msg, url, line, col, error) => {
    if(window._explicit_die) {
        window._explicit_die = false;
        return;
    }
    const e = {
        message: msg,
        url: url,
        line: line,
        col: col,
        error: error
    };
    alert(JSON.stringify(e));
}